import React from 'react';
import { FaWhatsapp } from "react-icons/fa6";
import Navbar from './Common/Navbar';
import Footer from './Common/Footer';

const Layout = ({ children }) => {

    const MessageButton = () => {
        return (
            <button
                className="fixed right-4 bottom-4 p-2 bg-textwhite rounded-full shadow-lg hover:bg-gray-300 hover:scale-105 ease-in-out hover:duration-300 focus:outline-none transition-transform transform-gpu"
            >
                <FaWhatsapp size={32} color='#10b416'/>
            </button>
        );
    };

    return (
        <React.Fragment>
            <Navbar />
            <React.Fragment>
                {children}
                <MessageButton />
            </React.Fragment>
            <Footer />
        </React.Fragment>
    );
};

export default Layout;