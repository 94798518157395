import React, { useState } from "react";
import logoImage from "../Assets/Navbar/bangkok-spa-logo.png"
import { Link } from "react-router-dom";
import BookNowModal from "../Components/BookNow/BookNowModal";
import Header from "./Header";

const Navbar = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  return (
    <React.Fragment>
      <Header />
      <BookNowModal
        bookNowModal={bookNowModal}
        setBookNowModal={setBookNowModal}
      />
      <nav className="bg-white p-4 shadow-lg flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center mb-4 md:mb-0">
          <img src={logoImage} alt="Logo" className="h-20 w-auto mr-2" />
        </div>

        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
          <Navlink to="/">Home</Navlink>

          <Navlink to="/about">About</Navlink>

          <Navlink to="/services">Services</Navlink>

          <Navlink to="/gallery">Gallery</Navlink>

          <Navlink to="/contact">Contact</Navlink>
        </div>

        <button
          onClick={() => setBookNowModal(true)}
          className="bg-mainColor text-white px-4 py-2 mt-4 md:mt-0 rounded-md hover:bg-btnHoverColor"
        >
          Book Now
        </button>
      </nav>
    </React.Fragment>
  );
};

const Navlink = ({ to, children }) => (
  <div className="text-gray-800 hover:text-btnHoverColor font-bold tracking-wider">
    <Link to={to} className="px-4 py-2 rounded-md focus:outline-none focus:bg-primaryBgColor">{children}</Link>
  </div>
);

export default Navbar;
