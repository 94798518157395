import React from 'react'
import About from '../Components/AboutPage/About'

const AboutUs = () => {
  return (
    <div>
      <About/>
    </div>
  )
}

export default AboutUs
