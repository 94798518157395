import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import cardImage1 from "../Assets/Home/bodyScrub.jpg";
import cardImage2 from "../Assets/Home/facialCare.jpg";
import cardImage3 from "../Assets/Home/aromaoil.jpeg";
import cardImage4 from "../Assets/Home/wineTherapy.jpg";
import cardImage5 from "../Assets/Home/balinesemassage.jpg";
import cardImage6 from "../Assets/Home/deepTissueMassage.jpg";
import services from "../Assets/Services/service.jpg";
import FootFlexology from "../Assets/Services/footFleexology.jpg";
import FourHand from "../Assets/Services/fourHand.jpg";
import swedish from "../Assets/Services/swedishmassage.jpg";
import Thaimassage from "../Assets/Services/Thaimassage.jpg";
import FullBody from "../Assets/Services/fullbodyMassage.jpg";
import SpacialMassage from "../Assets/Services/speacialTherapy.webp";
import BookNowModal from "../Components/BookNow/BookNowModal";

const Services = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  return (
    <div
      class=" bg-textwhite w-full min-h-screen"
      style={{ fontFamily: "serif" }}
    >
      <BookNowModal
        bookNowModal={bookNowModal}
        setBookNowModal={setBookNowModal}
      />
      <div
        className="flex flex-col md:flex-row opacity-85 place-content-center md:pl-8 bg-cover"
        style={{ backgroundImage: `url(${services})` }}
      >
        <div className="text-white text-center lg:p-24 tracking-wider lg:w-1/2">
          <h1 className="text-3xl font-bold font-serif rounded-none p-2 lg:p-4 transition duration-300 text-white">
            SERVICES
          </h1>
        </div>
      </div>
      <div class="container p-5 ml-auto mr-auto flex flex-wrap items-start gap-7 justify-evenly">
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={cardImage2}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  FACIAL CARE
                </h5>
                <span class="text-base text-black leading-none">
                  "Revitalize your skin with our luxurious facials at.
                  Experience a radiant glow as our skilled therapists pamper you
                  with the finest skincare rituals. "
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={cardImage1}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  BODY SCRUBS
                </h5>
                <span class="text-base text-black leading-none">
                  "Renew your skin's radiance with our rejuvenating body scrubs
                  at. Let our expert therapists exfoliate and revitalize your
                  skin ."
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={cardImage3}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  AROMA OIL
                </h5>
                <span class="text-base text-black leading-none">
                  "Embark on a sensory journey with our aromatic oil massages.
                  Indulge in the perfect blend of soothing scents and expert
                  touch"
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={cardImage4}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  WINE MASSAGE
                </h5>
                <span class="text-base text-black leading-none">
                  "Experience the epitome of relaxation with our wine-infused
                  massage. Indulge your senses as antioxidants and rich aromas
                  of wine blend seamlessly with expert massage techniques."
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={cardImage5}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  BALINESE MASSAGE
                </h5>
                <span class="text-base text-black leading-none">
                  "Transport yourself to the serene beauty of Bali with our
                  Balinese Massage. Immerse in the rhythmic harmony of ancient
                  techniques, expertly performed to release tension and restore
                  balance."
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={cardImage6}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  DEEP TISSUE
                </h5>
                <span class="text-base text-black leading-none">
                  "Revitalize your body and soothe your muscles with our Deep
                  Tissue Massage. Experience the therapeutic touch as skilled
                  therapists target deep-seated tension."
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={FootFlexology}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  FOOT FLEXOLOGY
                </h5>
                <span class="text-base text-black leading-none">
                  "Step into relaxation with our Foot Reflexology. Indulge in a
                  therapeutic journey as skilled therapists apply pressure to
                  specific points."
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={FourHand}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  FOUR HAND
                </h5>
                <span class="text-base text-black leading-none">
                  "Experience the pinnacle of relaxation with our Four-Hand
                  Massage. Let two skilled therapists synchronize their
                  movements"
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={swedish}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  SWEDISH MASSAGE
                </h5>
                <span class="text-base text-black leading-none">
                  "Embark on a journey of relaxation with our Swedish Massage.
                  Drift into tranquility as our skilled therapists employ long.
                  "
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={Thaimassage}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  THAI MASSAGE
                </h5>
                <span class="text-base text-black leading-none">
                  "Indulge in the therapeutic benefits of acupressure,
                  stretching, and rhythmic compressions, expertly performed by
                  our skilled therapists.
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={FullBody}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  FULL BODY MASSAGE
                </h5>
                <span class="text-base text-black leading-none">
                  Indulge in a soothing journey that encompasses every inch of
                  your body, leaving you feeling renewed and revitalized.
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img
                src={SpacialMassage}
                alt=""
                class="h-64 w-64 rounded-full ml-auto mr-auto"
              />
            </figure>
            <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
              <div>
                <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                  SPECIAL MASSAGE
                </h5>
                <span class="text-base text-black leading-none">
                  "Experience the extraordinary with our Special Massage at. Tailored for ultimate relaxation and rejuvenation, it's
                  a unique blend of luxury and personalized care."
                </span>
              </div>
              <div class="flex justify-center items-center">
                <button
                  href="javascript:;"
                  onClick={() => setBookNowModal(true)}
                  class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                >
                  BOOK NOW &nbsp;
                  <FaArrowRight size={15} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
