import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import meeting from "../../Assets/Home/meeting.jpeg";
import treatment from "../../Assets/Home/treatment.jpg";
import finalizing from "../../Assets/Home/finalizing.jpg";
import BookNowModal from "../BookNow/BookNowModal";

const HowItWorks = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  return (
    <div>
      <BookNowModal
        bookNowModal={bookNowModal}
        setBookNowModal={setBookNowModal}
      />
      <div
        class="flex items-center w-full min-h-screen"
        style={{ fontFamily: "serif" }}
      >
        <div class="container ml-auto mr-auto flex flex-wrap items-start justify-evenly">
          <div class="w-full pl-5 lg:pl-2 mb-4 mt-4 text-center">
            <h1 class="text-3xl lg:text-4xl text-black font-extrabold">
              HOW IT WORKS
            </h1>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img
                  src={meeting}
                  alt=""
                  class="h-64 w-64 rounded-full ml-auto mr-auto"
                />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                    MEETING
                  </h5>
                  <span class="text-base text-grayTextColor leading-none">
                    "Where meetings meet tranquility, ideas flow effortlessly,
                    and collaboration becomes a spa for innovation."
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    href="javascript:;"
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img
                  src={treatment}
                  alt=""
                  class="h-64 w-64 rounded-full ml-auto mr-auto"
                />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                    TREATMENTS
                  </h5>
                  <span class="text-base text-grayTextColor leading-none">
                    "Indulge in self-care, where spa treatments become a
                    symphony of relaxation, rejuvenation, and radiant bliss."
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    href="javascript:;"
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img
                  src={finalizing}
                  alt=""
                  class="h-64 w-64 rounded-full ml-auto mr-auto"
                />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                    FINALIZING
                  </h5>
                  <span class="text-base text-grayTextColor leading-none">
                    "Finalize your journey to tranquility. Immerse in the
                    serenity of our spa, where relaxation meets rejuvenation."
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    href="javascript:;"
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
