import React from "react";
import yearsExperience from "../../Assets/Home/yearsexperience.png";
import WellnessSpa from "../../Assets/Home/wellnessSpa.png";
import herbalWellness from "../../Assets/Home/herbalwellness.png";
import happyClient from "../../Assets/Home/happyClients.png";

const YearsExperience = () => {
  return (
    <div className="animate-pulse tracking-wider bg-primaryBgColor w-full flex flex-wrap gap-6 lg:gap-0 lg:justify-between sm:justify-center px-10 pb-8">
      {/* Each block for a section */}
      <div className="flex items-center">
       <div className="mt-6">
      <img src={yearsExperience} alt="" className="w-20 h-20"/>
    </div>
        <div className="flex flex-col pl-4">
          <h1>23</h1>
          <p className="text-grayTextColor font-bold">Years Experience</p>
        </div>
      </div>

      <div className="flex items-center">
        <div className="mt-6">
        <img src={WellnessSpa} alt="" className="w-20 h-20" />
        </div>
        <div className="flex flex-col pl-4">
          <h1>860</h1>
          <p className="text-grayTextColor font-bold">Wellness Spa</p>
        </div>
      </div>

      <div className="flex items-center">
        <div className="mt-6">
        <img src={herbalWellness} alt="" className="w-20 h-20" />
        </div>
        <div className="flex flex-col pl-4">
          <h1>30</h1>
          <p className="text-grayTextColor font-bold">Herbal Treatments</p>
        </div>
      </div>

      <div className="flex items-center">
        <div className="mt-6">
        <img src={happyClient} alt="" className="w-20 h-20" />
        </div>
        <div className="flex flex-col pl-4">
          <h1>980</h1>
          <p className="text-grayTextColor font-bold">Happy Clients</p>
        </div>
      </div>
    </div>
  );
};

export default YearsExperience;
