import React, { useState } from "react";
import whatelseBg from "../../Assets/Home/spa-bg.jpg";
import spaIcon from "../../Assets/Home/image-icon.png";
import BookNowModal from "../BookNow/BookNowModal";
const BookNow = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  return (
    <div
      className="bg-cover p-16 lg:py-32 flex flex-col lg:flex-col justify-center items-center relative"
      style={{ backgroundImage: `url(${whatelseBg})` }}
    >
      <BookNowModal
        bookNowModal={bookNowModal}
        setBookNowModal={setBookNowModal}
      />
      <div className="text-textwhite text-center lg:text-left lg:w-1/2">
        <img className="text-btnHoverColor" src={spaIcon} alt="Spa Icon" />

        <h1 className="text-white text-3xl lg:text-5xl font-serif leading-snug mb-6">
          Our Spa Center is the True Splendor
        </h1>

        <div className="absolute inset-0 bg-black bg-opacity-30"></div>

        <button
          onClick={() => setBookNowModal(true)}
          className="text-textwhite bg-mainColor font-bold text-base rounded-md p-2 lg:p-4 transition duration-300 hover:bg-btnHoverColor hover:bg-opacity-80"
        >
          BOOK NOW
        </button>
      </div>
    </div>
  );
};

export default BookNow;
