import React from "react";
import { IoIosSend } from "react-icons/io";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="">
      <footer className="bg-textBlack dark:bg-[#27252a] pt-4">
        <div className="mx-auto w-full max-w-screen-xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-4">
            <div className="mb-8 md:mb-0">
              <ul className="dark:grayTextColor font-medium">
                <li className="mb-4">
                  <p className="text-grayTextColor text-2xl font-serif">
                    bangkokspa2017@gmail.com
                  </p>
                </li>
                <li className="mb-4">
                  <p className="text-grayTextColor text-2xl font-serif">
                    9861688844
                  </p>
                </li>
                <li className="mb-4 relative flex items-center">
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="p-4 pl-12 w-full bg-black text-white"
                  />
                </li>
              </ul>
            </div>
            <div className="mb-8">
              <h2 className="mb-6 text-sm font-bold text-white uppercase">
                Links
              </h2>
              <ul className="text-grayTextColor dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <div className="hover:underline">About</div>
                </li>
                <li className="mb-4">
                  <div className="hover:underline">Pricing Plans</div>
                </li>
                <li className="mb-4">
                  <div className="hover:underline">Promotions</div>
                </li>
                <li className="mb-4">
                  <div className="hover:underline">Contact</div>
                </li>
              </ul>
            </div>
            <div className="mb-8">
              <h2 className="mb-6 text-sm font-bold text-white uppercase">
                Timing
              </h2>
              <ul className="text-grayTextColor dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <div className="hover:underline">Monday: 9:00 to 6:00</div>
                </li>
                <li className="mb-4">
                  <div className="hover:underline">
                    Tuesday to Friday: 8:00 to 19:00
                  </div>
                </li>
                <li className="mb-4">
                  <div className="hover:underline">Saturday: 8:00 to 3:30</div>
                </li>
              </ul>
            </div>
            <div>
              <ul className="text-grayTextColor dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <ul className="flex gap-6">
                    <li>
                      <Link to="https://www.facebook.com/bangkokspa2017">
                        <FaFacebook size={25} />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/bangkok_spa_21/">
                        <AiFillInstagram size={28} />
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="mb-4">
                  <p>
                    3rd Floor, Plot No 622 Janpath, Sahid Nagar,
                    <br />
                    Bhubaneswar-751007
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <footer className="footer w-full dark:bg-[#27252a] shadow text-grayTextColor px-5 pb-4">
            <div className="sm:flex sm:justify-between">
              <div className="mb-4 md:mb-0">
                2024 © <span className="text-grayTextColor font-bold">SPA</span>
                .
              </div>

              <div className="text-sm">
                Design & Develop by{" "}
                <a
                  href="https://webbocket.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-textwhite font-bold"
                >
                  Web_Bocket Pvt. Ltd.
                </a>
              </div>
            </div>
          </footer>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
