import React from "react";
import abouticon from "../../Assets/AboutUS/headerImg.jpg";
import aboutimg1 from "../../Assets/AboutUS/woman-relaxing-spa.jpg";
import aboutimg2 from "../../Assets/AboutUS/about-2.jpg";
import aboutimg3 from "../../Assets/AboutUS/about-3.jpg";
import aboutimg4 from "../../Assets/AboutUS/about-4.jpg";
import aboutimg5 from "../../Assets/AboutUS/about-5.jpg";
import aboutimg6 from "../../Assets/AboutUS/about-6.jpg";
import aboutimg7 from "../../Assets/AboutUS/about-7.jpg";
import aboutimg8 from "../../Assets/AboutUS/about-8.jpg";
import aboutimg9 from "../../Assets/AboutUS/about-9.jpg";

const About = () => {
  return (
    <div>
      <div className="flex-grow relative">
        <div className="relative">
          <img className="w-full h-auto" src={abouticon} alt="Tourimg" />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
            About
          </div>
        </div>
      </div>

      <div id="about" class="relative bg-textwhite overflow-hidden mt-16">
        <div class="max-w-7xl mx-auto">
          <div class="relative z-10 pb-8 bg-textwhite sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-textwhite  transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100"></polygon>
            </svg>

            <div class="pt-1"></div>

            <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div class="sm:text-center lg:text-left">
                <h2 class="my-6 text-2xl tracking-tight font-serif font-bold text-mainColor sm:text-3xl md:text-4xl">
                  Who Are We
                </h2>

                <p class="text-grayTextColor w-full md:w-5/6 xl:w-3/4 font-sans italic text-base text-justify">
                  Welcome to Bangkok Spa, your oasis of tranquility. Indulge in
                  our diverse range of services, from the soothing Aroma Oil
                  Massage to the invigorating Deep Tissue Massage. Our expert
                  therapists blend traditional techniques with luxurious
                  treatments like the Red Wine Massage for a truly revitalizing
                  experience. Immerse yourself in relaxation at Bangkok Spa,
                  where we tailor every session to pamper both body and mind,
                  ensuring you leave feeling refreshed and rejuvenated.
                </p>
              </div>
            </main>
          </div>
        </div>
        <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            class="h-64 md:h-80 lg:h-full w-full object-cover object-top"
            src={aboutimg1}
            alt=""
          />
        </div>
      </div>

      <div>
        <section className="flex items-center bg-textwhite font-poppins">
          <div className="justify-center flex-1 max-w-6xl px-4 py-2 mx-auto lg:py-6 md:px-6">
            <div className="flex flex-wrap items-center">
              <div className="w-full px-4 mb-10 xl:w-1/2 lg:mb-8">
                <div className="flex flex-wrap">
                  <div className="w-full px-4 md:w-1/2">
                    <img
                      src={aboutimg6}
                      alt=""
                      className="object-cover w-full mb-6 rounded-lg h-80"
                    />
                    <img
                      src={aboutimg7}
                      alt=""
                      className="object-cover w-full rounded-lg h-80"
                    />
                  </div>
                  <div className="w-full px-4 md:w-1/2 xl:mt-11">
                    <img
                      src={aboutimg8}
                      alt=""
                      className="object-cover w-full mb-6 rounded-lg h-80"
                    />
                    <img
                      src={aboutimg9}
                      alt=""
                      className="object-cover w-full rounded-lg h-80"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full px-4 mb-10 xl:w-1/2 lg:mb-8">
                <span className="text-xl font-semibold text-mainColor dark:text-[#8B898E]">
                  Why choose us
                </span>
                <h2 className="mt-2 mb-4 text-2xl font-bold text-ttextBlack dark:text-gray-300 ">
                  We are providing a better facility
                </h2>
                <p className="mb-4 text-base text-justify italic leading-7 text-grayTextColor dark:text-gray-400">
                  Choose Bangkok Spa for an unparalleled wellness experience.
                  Our skilled therapists blend traditional techniques with
                  luxurious treatments, ensuring a customized approach to your
                  well-being.
                  <br />
                  Immerse yourself in our serene ambiance and diverse services,
                  from Aroma Oil to Red Wine Massage, crafted to rejuvenate and
                  refresh. With a commitment to excellence, we prioritize your
                  unique needs, making Bangkok Spa the ideal destination for
                  those seeking a personalized escape from the stresses of daily
                  life. Trust us to guide you on a journey of relaxation and
                  renewal.
                </p>
                
                <a
                  href="#"
                  className="px-4 py-2 text-textwhite font-extrabold bg-mainColor hover:bg-textBlack tracking-widest hover:text-white rounded-md"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="flex items-center bg-textwhite font-poppins">
        <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto lg:py-6 md:px-6">
          <div className="flex flex-wrap items-center">
            <div className="w-full px-4 mb-10 xl:w-1/2 lg:mb-8">
              <h2 className="mt-2 mb-4 text-2xl font-bold text-textBlack ">
                Our Vision
              </h2>
              <p className="mb-4 text-base text-justify italic leading-7 text-[#8D8990] dark:text-gray-400">
                At Bangkok Spa, our vision is to be the epitome of holistic
                well-being, providing a sanctuary where individuals can escape,
                rejuvenate, and rediscover balance in their lives. We aspire to
                set the standard for excellence in the spa industry by
                continually innovating our services, embracing traditional
                wisdom, and incorporating cutting-edge practices. Our commitment
                is to create an environment where each guest experiences
                personalized care, fostering a profound sense of relaxation and
                vitality. Bangkok Spa aims to be the destination of choice for
                those seeking not just a service, but a transformative journey
                towards enhanced health and inner harmony.
                <br />
                <h2 className="mt-2 mb-4 text-2xl font-bold text-textBlack">
                  Our Mission
                </h2>
                Our mission at Bangkok Spa is to inspire a profound sense of
                well-being by offering an array of exceptional spa services that
                cater to the diverse needs of our guests. We are dedicated to
                providing a haven of tranquility, where skilled therapists
                employ a harmonious blend of traditional and contemporary
                techniques to promote relaxation and rejuvenation. We strive to
                exceed expectations by prioritizing personalized care, ensuring
                each individual receives a tailored experience that addresses
                their unique wellness goals. Committed to excellence, we aim to
                create a nurturing space that fosters physical, mental, and
                emotional renewal, making Bangkok Spa a destination where
                self-care becomes a transformative and integral part of
                our guest's lives.
              </p>
              
              <a
                href="#"
                className="px-4 py-2 text-textwhite font-extrabold bg-mainColor hover:bg-textBlack tracking-widest hover:text-textwhite rounded-md "
              >
                Learn more
              </a>
            </div>
            <div className="w-full px-4 mb-10 xl:w-1/2 lg:mb-8">
              <div className="flex flex-wrap">
                <div className="w-full px-4 md:w-1/2">
                  <img
                    src={aboutimg2}
                    alt=""
                    className="object-cover w-full mb-6 rounded-lg h-80"
                  />
                  <img
                    src={aboutimg3}
                    alt=""
                    className="object-cover w-full rounded-lg h-80"
                  />
                </div>
                <div className="w-full px-4 md:w-1/2 xl:mt-11">
                  <img
                    src={aboutimg4}
                    alt=""
                    className="object-cover w-full mb-6 rounded-lg h-80"
                  />
                  <img
                    src={aboutimg5}
                    alt=""
                    className="object-cover w-full rounded-lg h-80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
