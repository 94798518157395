import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  return (
    <div className="flex w-1/2 justify-center">
      <form className="container p-4 w-full bg-textwhite">
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <div className='mb-4 w-full'>
            <input
              type="text"
              name="name"
              placeholder='Full Name'
              value={formData.name}
              onChange={handleChange}
              className="border lg:w-full rounded p-2 text-grayTextColor bg-textwhite focus:outline-1 focus:ring focus:ring-gray-400"
              required
            />
          </div>
          <div className='mb-4 w-full'>
            <input
              type="email"
              name="email"
              placeholder='Email Address'
              value={formData.email}
              onChange={handleChange}
              className="border lg:w-full rounded p-2 text-grayTextColor bg-textwhite focus:outline-1 focus:ring focus:ring-gray-400"
              required
            />
          </div>
          <div className='mb-4 w-full'>
            <input
              type="phone"
              name="phone"
              placeholder='Phone No'
              value={formData.phone}
              onChange={handleChange}
              className="border lg:w-full rounded p-2 text-grayTextColor bg-textwhite focus:outline-1 focus:ring focus:ring-gray-400"
              required
            />
          </div>
          <div className='mb-8 w-full'>
            <input
              type="text"
              name="subject"
              placeholder='Subject'
              value={formData.subject}
              onChange={handleChange}
              className="border lg:w-full rounded p-2 text-grayTextColor bg-textwhite focus:outline-1 focus:ring focus:ring-gray-400"
              required
            />
          </div>
        </div>
        <div className='mb-4 w-full'>
          <textarea
            name="message"
            value={formData.message}
            placeholder='Write a Message'
            onChange={handleChange}
            className="border lg:w-full rounded p-2 text-grayTextColor bg-textwhite focus:outline-1 focus:ring focus:ring-gray-400"
            required
          />
        </div>
        <div>
          <button
            type="submit"
            className="text-textwhite font-extrabold mb-8 bg-mainColor rounded-md px-3 py-2 text-sm hover:bg-btnHoverColor tracking-widest hover:textwhite">
            Send a Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;