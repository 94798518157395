import React from "react";
import { TfiEmail } from "react-icons/tfi";
import { FaPhone } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <nav class="bg-primaryBgColor p-4">
      <div class="container mx-auto flex flex-col lg:flex-row lg:justify-between items-center">
        <div className="flex flex-col lg:flex-row">
          <div class="flex items-center mb-2 lg:mb-0">
            <span className="p-2 bg-mainColor rounded-full mr-2">
              <TfiEmail size={15} color="white" />
            </span>
            <span className="text-[#89878C] hover:text-mainColor tracking-wide">
              bangkokspa2017@gmail.com
            </span>
          </div>

          <div class="flex items-center ml-4 mb-2 lg:mb-0">
            <span className="p-2 bg-mainColor rounded-full mr-2">
              <FaPhone size={12} color="white" />
            </span>
            <p className="text-[#89878C] hover:text-mainColor"> 9861688844</p>
          </div>
        </div>

        <div class="flex items-center">
          <div className="mr-4 hover:text-mainColor">
            <Link to="https://www.facebook.com/bangkokspa2017">
              <FaFacebook size={20} />
            </Link>
          </div>

          <div className="hover:text-mainColor">
            <Link to="https://www.instagram.com/bangkok_spa_21/">
              <AiFillInstagram size={23} />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
