import React from "react";
import heroImg from "../../Assets/Contact/headerContact.jpg";
import ContactCard from "./ContactCard";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import ContactForm from "./ContactForm";

const ContactDesign = () => {
  return (
    <div className="bg-white">
      <React.Fragment>
        <div className="flex-grow relative">
          <div className="relative">
            <img className="w-full h-auto" src={heroImg} alt="Tourimg" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-textwhite text-4xl font-semibold">
              Contact
            </div>
          </div>
        </div>
      </React.Fragment>
      <div>
        <div className="w-full md:mt-4">
          <h4 className="text-center pt-4 font-medium font-sans text-lg md:text-xl tracking-widest text-btnHoverColor">
            LOCATIONS
          </h4>
          <h1 className="text-center pt-3 leading-[16px] md:leading-6 font-serif text-3xl md:text-5xl tracking-wider">
            Our Spa Centers
          </h1>
        </div>

        <div className="h-auto">
          <div className="flex flex-wrap justify-center sm:grid-cols-2">
            <ContactCard
              location="Austin"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />

            <ContactCard
              location="Boston"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />

            <ContactCard
              location="New York"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />

            <ContactCard
              location="Baltimore"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />
          </div>
        </div>
      </div>

      <div className="mt-10 ml-9 md:mt-10 flex flex-col md:flex-row mx-4 md:mx-16 justify-center sm:justify-evenly">
        <div className="mb-8 md:mb-0 md:mr-8">
          <span className="inline-block text-[16px] leading-[16px] text-mainColor font-bold tracking-wider ">
            CONTACT US
          </span>
          <h2 className="text-black leading-[49px] mt-2 md:mt-7 text-[32px] md:text-[44px] m-0 font-serif">
            Send a Message
          </h2>
          <p className="text-grayTextColor text-base mt-2 md:mt-7 leading-6 md:leading-8">
            Interested in our spa services? We want your <br />
            <span> spa experience to be a memorable one. </span>
          </p>

          <div className="flex mt-4 md:mt-10 gap-4">
            <span className="p-2 bg-textwhite rounded-full mr-2 hover:bg-btnHoverColor hover:text-textwhite">
              <a href="#">
                {" "}
                <FaTwitter size={20} />{" "}
              </a>
            </span>
            <span className="p-2 bg-textwhite rounded-full mr-2 hover:bg-btnHoverColor hover:text-textwhite">
              <a href="#">
                {" "}
                <FaFacebook size={20} />{" "}
              </a>
            </span>
            <span className="p-2 bg-textwhite rounded-full mr-2 hover:bg-btnHoverColor hover:text-textwhite">
              <a href="#">
                {" "}
                <IoLogoInstagram size={20} />{" "}
              </a>
            </span>
          </div>
        </div>

        <ContactForm />
      </div>
    </div>
  );
};

export default ContactDesign;
