import React from 'react';

const ContactCard = ({
  location,
  address,
  timing,
  mailId,
  phoneNo
}) => {
  return (
    <div className="w-full md:w-[300px] text-center rounded-md p-5 md:p-8 lg:p-10 hover:shadow-xl">
      <h3 className='font-bold text-lg md:text-xl lg:text-2xl text-ttextBlack font-serif'>{location}</h3>
      <p className='mt-2 md:mt-3 lg:mt-4 text-grayTextColor text-sm md:text-md lg:text-lg'>
        {address}
        <br />
        {timing}
      </p>
      <div className='mt-2 md:mt-3 lg:mt-4 text-mainColor text-sm md:text-md lg:text-lg'>
        <a href={`mailto:${mailId}`}>{mailId}</a>
        <br />
        <a href={`tel:${phoneNo}`}>{phoneNo}</a>
      </div>
    </div>

  );
};

export default ContactCard;
